import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/accueil.css'
import './../assets/css/normalize.css'

import groupWorking from './../assets/img/group_working.svg'
import logoHome from './../assets/img/logo_home.svg'
import subtitleWhite from './../assets/img/subtitle_white_stroke.svg'
import subtitleBlue from './../assets/img/subtitle_blue_stroke.svg'
import arrowDown from './../assets/img/arrow_down.svg'
import city from './../assets/img/city.png'
import ambulanceIcon from './../assets/img/ambulance_icon.svg'
import hospitalIcon from './../assets/img/hospital_icon.svg'
import littleBlueStroke from './../assets/img/little_blue_stroke.png'
import littleEmblem from './../assets/img/little_emblem.svg'
import sickIcon from './../assets/img/sick_icon.svg'
import littleEmblemBlue from './../assets/img/little_emblem_blue.svg'
import pcWorking from './../assets/img/pc_working.svg'
import pcWorkingRespoonsive from './../assets/img/pc_working_respoonsive.svg'
import screenCodeIcon from './../assets/img/screen_code_icon.svg'
import searchIcon from './../assets/img/search_icon.svg'
import dollarTicketIcon from './../assets/img/dollar_ticket_icon.svg'
import keyboardIcon from './../assets/img/keyboard_icon.svg'
import pc from './../assets/img/pc.svg'
import pcResponsive from './../assets/img/pc_responsive.svg'

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Accueil | Paramedic</title>
      </Helmet>
      <section id='section_acceuil_home'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-7'>
              <img
                className='slide-bottom delay_100' src={groupWorking}
                alt='Illustration groupe de personnes travaillant sur pc' draggable='false'
              />
            </div>

            <div className='col-lg-5 delay_500'>
              <div className='row'>
                <img
                  src={logoHome} alt='Logo Paramedic Blanc' className='logo_home'
                  data-aos='fade-left' data-aos-duration='1000' draggable='false'
                />
              </div>

              <img
                src={subtitleWhite} alt='Traits blancs à bouts arrondis'
                className='subtitle_white_stroke' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='500'
                draggable='false'
              />
              <p data-aos='fade-left' data-aos-duration='1000' data-aos-delay='650'><span>Paramedic</span> est une
                plateforme de gestion et d’optimisation des transports sanitaires, de la prise en charge à la
                facturation.
              </p>
              <Link to='#section_acceuil_why' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='750'>En savoir
                plus
              </Link>
            </div>

          </div>
        </div>
      </section>

      <section id='section_acceuil_why'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-5 delay_500'>
              <h3 className='delay_200' data-aos='fade-right' data-aos-duration='1000'>Pourquoi</h3>
              <h2 className='delay_200' data-aos='fade-right' data-aos-duration='1000'>Paramedic ?</h2>
              <img
                src={subtitleBlue} alt='Traits bleus à bouts arrondis'
                className='subtitle_blue_stroke delay_200' data-aos='fade-right' data-aos-duration='1000'
                draggable='false'
              />
              <p className='delay_400' data-aos='fade-right' data-aos-duration='1000' data-aos-delay='500'>
                Paramedic assiste les différents acteurs de la chaîne du transport sanitaire via un service technique
                entièrement dématérialisé.
              </p>
              <p className='delay_400' data-aos='fade-right' data-aos-duration='1000' data-aos-delay='750'>
                Les établissements de santé se déchargent de la gestion des transports patients tandis que les
                transporteurs optimisent leur offre.
              </p>
              <Link to='#section_acceuil_us'>
                <img
                  draggable='false'
                  src={arrowDown}
                  alt='Fleche bleu dans un cercle dirigée vers le bas'
                  className='arrow_down bounce'
                />
              </Link>
            </div>
            <div className='col-lg-7'>
              <img
                src={city} alt='Illustration de divers moyens de transport en ville'
                className='city_illustration delay_750 slide-bottom' draggable='false'
              />
            </div>
          </div>
        </div>
      </section>

      <section id='section_acceuil_us'>
        <div className='container'>
          <div className='row delay_500'>
            <div className='col-lg-3'>
              <div className='box'>
                <img
                  src={ambulanceIcon} alt='Ambulance bleue' className='box-image'
                  draggable='false'
                />
                <h4>Sociétés de transports sanitaires</h4>
                <img
                  src={littleBlueStroke} alt='Trait fin bleu' className='little_blue_stroke'
                  draggable='false'
                />
                <ul>
                  <li>• Transports supplémentaires sur une base 100 % CPAM;</li>
                  <li>• Limitation des transports à vide;</li>
                  <li>• Respect du tour de rôle;</li>
                  <li>• Réduit les délais de paiement;</li>
                  <li>• Sans engagement, sans abonnement, sans surcoûts cachés.</li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 middle-box'>
              <div className='box'>
                <img src={hospitalIcon} alt='Hôpital bleu' className='box-image' draggable='false' />
                <h4>Établissements de santé</h4>
                <img
                  src={littleBlueStroke} alt='Trait fin bleu' className='little_blue_stroke'
                  draggable='false'
                />
                <ul>
                  <li>• Prise de commande simpliﬁée;</li>
                  <li>• Centralisation des demandes;</li>
                  <li>• Suivi en temps réel;</li>
                  <li>• Détection de l’article 80;</li>
                  <li>• Tableau de bord et analyse des données;</li>
                  <li>• Assistance téléphonique;</li>
                  <li>• Gestion des incidents;</li>
                  <li>• Barrière contre la facturation abusive.</li>
                </ul>
              </div>
              <img
                src={littleEmblem} alt='Petite emblème blanche Paramedic'
                className='little_emblem rotating' draggable='false'
              />
            </div>
            <div className='col-lg-3'>
              <div className='box'>
                <img
                  src={sickIcon} alt='Patient malade bleu' className='box-image'
                  draggable='false'
                />
                <h4>Patients</h4>
                <img
                  src={littleBlueStroke} alt='Trait fin bleu' className='little_blue_stroke'
                  draggable='false'
                />
                <ul>
                  <li>• Véhicule adapté;</li>
                  <li>• Diminution du temps d’attente;</li>
                  <li>• Meilleure prise en charge.</li>
                </ul>
                <img
                  src={littleEmblemBlue} alt='Petite emblème bleue Paramedic'
                  className='little_emblem_blue rotating' draggable='false'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='section_acceuil_solutions'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-4 delay_250'>
              <img
                src={pcWorking} alt='Ordinateur avec travaux en cours'
                className='slide-bottom desktop-solutions' draggable='false'
              />
              <img
                src={pcWorkingRespoonsive} alt='Ordinateur avec travaux en cours'
                className='slide-bottom mobile-solutions' draggable='false'
              />
            </div>
            <div className='col-lg-8'>
              <h3 className='delay_500' data-aos='fade-left' data-aos-duration='800'>Nos solutions</h3>
              <img
                src={subtitleBlue} alt='Traits bleus à bouts arrondis'
                className='subtitle_blue_stroke' data-aos='fade-left' data-aos-duration='800' draggable='false'
              />
              <ul>
                <li className='dark_li delay_600' data-aos='fade-left' data-aos-duration='800' data-aos-delay='100'>
                  <img
                    draggable='false' src={screenCodeIcon} alt='Ecran avec codes'
                    className='list_icon'
                  />Commande
                </li>
                <li
                  className='light_li delay_700' data-aos='fade-left' data-aos-duration='800'
                  data-aos-delay='200'
                ><img
                  draggable='false' src={searchIcon} alt='Loupe'
                  className='list_icon'
                 />Traçabilité
                </li>
                <li className='dark_li delay_800' data-aos='fade-left' data-aos-duration='800' data-aos-delay='300'>
                  <img
                    draggable='false' src={dollarTicketIcon}
                    alt='Ticket de caisse avec symbole dollar' className='list_icon'
                  />Pilotage des dépenses
                </li>
                <li
                  className='light_li delay_900' data-aos='fade-left' data-aos-duration='800'
                  data-aos-delay='400'
                ><img
                  draggable='false' src={keyboardIcon} alt='Clavier'
                  className='list_icon'
                 />Suivi en temps réel
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id='section_acceuil_notebook'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 slide-bottom delay_250'>
              <img
                className='notebook-desktop' src={pc} alt='Ordinateur Portable avec Logo Paramedic'
                draggable='false'
              />
              <img
                className='notebook-mobile' src={pcResponsive}
                alt='Ordinateur Portable avec Logo Paramedic' draggable='false'
              />
            </div>
          </div>
          <div className='row buttons delay_500'>
            <Link to='/contact'>Nous contacter</Link>
            <a href='#'>Le blog</a>
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexPage
